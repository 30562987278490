.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-container {
  margin: 0 -10px;
  padding: 0;
  margin-top: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  /* border: ; */
}

.steps-content {
  margin-top: 16px;
  /* text-align: center; */
  background-color: #fff;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  /* padding-bottom: 1rem; */
  /* border: 1px solid red; */
}

.steps-action {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.step-header {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 0;
  background: #FFFFFF;
  /* style2 */
  /* border: 2px solid red; */
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  font-family: 'Poppins';
  /* border: 1px solid red; */
  margin: 0 -10px;
  /* margin-bottom: 8px; */
  padding: 0;

}

.add_deal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #FFFFFF;
  /* padding: 2rem 1rem; */
  background: linear-gradient(89.63deg, #2F80ED 3.62%, #88D3FD 109.65%);
  border-radius: 10px 0px 0px 10px;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid red; */
  border-radius: 10px;
  width: 100%;
}

.step {
  display: flex;
  flex-direction: column;
  border: 1px solid blue;
}

.step-label {
  font-family: 'Poppins', sans-serif;
}

.next_button {
  /* Auto layout */

  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  text-align: center;

  color: #FFFFFF;
  /* width: 194.78px;
  height: 44px; */
  left: calc(50% - 194.78px/2 + 583.11px);
  top: calc(50% - 44px/2 + 129px);

  /* primary */

  background: #2F80ED;
  box-shadow: 0px 0px 10px rgba(109, 166, 106, 0.14);
  border-radius: 4px;
}

.noi-card {
  min-height: 200px;
  /* min-width: 400px; */
  /* text-align: center; */
  background-color: #fff;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 1rem;
  /* margin-right: .1rem; */
}

.noi-container {
  margin-top: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  padding: 0;
  /* width: 100%; */
  /* border: 1px solid red; */
}

.noi-selected {
  margin-top: .6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  /* grid-gap: 5px; */
}

.noi-selected-button {
  background: #FFFFFF;
  border: 0.5px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: .6rem;
  color: #BDBDBD;
  /* padding: 0; */
  /* max-height: 1rem; */
}

.noi-selected-button-active {
  background: #FFFFFF;
  border: 0.5px solid #2F80ED;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: .6rem;
  color: #2F80ED;
}

/* .metrix-card{
  pa
} */

.matrix-card {
  margin-top: 16px;
  /* text-align: center; */
  background-color: #fff;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding-bottom: .8rem;
}

.pinfo-text-1 {
  color: #A2ACBD;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.pinfo-text-2 {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #56585D;
}

.pinfo-text-3 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 236% */

  display: flex;
  align-items: center;

  color: #56585D;
}

.pimg {
  padding: 0;
  height: 75%;
  /* border-radius: 1rem; */
}

.pimgsection {
  padding-bottom: 3rem;
}

.metrics-text-1 {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #A2ACBD;
}

.metrics-text-2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;

  color: #56585D;
}

.opDheader {
  background: #FCFCFC;
  padding: .3rem 1rem;
  margin-bottom: .5rem;
}

.cashflow-test-1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #56585D;
}

.cashflow-years {
  /* font-weight: 600; */
  /* font-family: ; */
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #A2ACBD;
}

.heading {
  color: #404756;
}

@media screen and (max-width:580px) {
  .step-label {
    display: none;
  }

  .add_deal {
    display: none;
  }

  .step-header {
    display: grid;
    grid-template-columns: 1fr;
  }

  .noi-container {
    margin-top: 18px;
    display: block;
    padding: 0;
  }

  .noi-card {
    margin-bottom: 1rem;
  }

  .xp-contentbar {
    margin-left: .7rem;
    margin-right: .7rem;
  }

  .next-button {
    font-size: small;
  }

  .pinfo-text-2 {
    margin-bottom: .5rem;
  }

  .pimg {
    /* padding: .5rem; */
    margin-top: 1rem;


  }

  /* .noi-selected-button-active .noi-selected-button{
    font-size: 1px;
  } */
}

.lable-color {
  color: #56585d
}

.card__hover {
  box-shadow: 10px 10px 10px #bbb;
  width: 370px;
}

.card__hover:hover {
  box-shadow: 10px 10px 10px #ccc;
  /* box-shadow: 0 0 20px 8px #d0d0d0; */
  transition: .4s;
}

.dashboard_hero_font {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #767676;
}

.google-map-box{
  width: 100%;
  height: 80%;
  margin-top: 1.6rem;
}

@media(max-width:578px){
  .custom-padding{
    padding-left: 4%;
  }
  .custom-padding-2{
    padding-left: 2rem;
  }
  .google-map-box{
    height: 100px;
  }
}
.colorbox{
  width: 4rem;
}
.classtext{
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  margin-bottom: 3px;
  text-align: center;
  color: white;
}
.classtext{
  margin-left: 10px;
}
.circle.small {
  border: 25px solid #CCCCCC;
  border-radius: 50%;
  width: 187px;
  height: 187px;
  z-index: 86;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;  
  color: #aaa;
  margin-top: .3rem;
}
.property-single-image{
  border-radius: 8px;
  max-width: 100%; 
  max-height: 340px;
  min-height: 340px
}
.ipad-toggle{
  display: block;
}
.mobile-toggle{
  display: none;
}
@media (min-width: 1440px) and (max-width: 2880px) {
  .card__hover {
    width: 380px;
  }
  .colorbox{
    width: 4rem;
  }
  .ipad-toggle{
    display: none;
  }
  .mobile-toggle{
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .card__hover {
    width: 320px;
  }
  .classtext{
    margin-left: 5px;
  }
  .colorbox{
    width: 3.5rem;
  }
  .class_amount{
    font-size: 12px;
    color: #0a0a0a;
  }
  .share-text{
    font-size: 13px;
    color: #000000;
  }
  .circle.small{
    border: 20px solid #CCCCCC;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    font-size: 24px;
  }
  .ipad-toggle{
    display: none;
  }
  .mobile-toggle{
    display: none;
  }
}
.investor-deal-image{
  padding: 0 1rem;
  min-height: 400px;
  border-radius: 10px;
}
.investor-small-image{
  height: 70px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .card__hover {
    width: 300px;
  }
  .colorbox{
    width: 3.5rem;
  }
  .classtext{
    margin-left: 5px;
  }
  .classtext{
    margin-left: 5px;
  }
  .class_amount{
    font-size: 11px;
    color: #000000;
  }
  .share-text{
    font-size: 12px;
    color: #000000;
  }
  .circle.small{
    border: 15px solid #CCCCCC;
    border-radius: 50%;
    width: 98px;
    height: 08px;
    font-size: 20px;
  }
  .investor-image-div{
    margin: .8rem 1rem .8rem 0rem;
    padding: 1rem 0rem;
    border-radius: 10px;
  }
  .noi-container {
    display: block;
    padding: 0;
  }
  .noi-card{
    margin-top: 18px;
  }
  .property-single-image{
    border-radius: 5px;
    max-width: 100%; 
    max-height: 250px;
    min-height: 250px;
    margin-top: 20px;
  }
  .investor-small-image{
    height: 100px;
    width: 100%
  }
  .nav-icon{
    width: 30px
  }
  .title-text{
    font-size: 28px;
  }
}
@media (max-width: 480px) and (max-width: 785px){
  .card__hover {
    width: 320px;
  }
  .colorbox{
    width: 3.5rem;
  }
  .classtext{
    margin-left: 5px;
  }
  .class_amount{
    font-size: 11px;
    color: #000000;
  }
  .share-text{
    font-size: 12px;
    color: #000000;
  }
  .investor-deal-image{
    min-height: 60px;
    max-height: 130px;
    padding-top: 1rem;
  }
  .investor-slide-box{
    /* margin: .8rem 2rem 0rem .8rem; */
    display: none;
  }
  .single-item img{
    padding: 0;
    margin: 1rem 0 0 0;
  }
  .circle.small{
    border: 20px solid #CCCCCC;
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  .circle.small{
    border: 20px solid #CCCCCC;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    font-size: 20px;
    margin-bottom: 1rem;
  }
  .property-single-image{
    border-radius: 5px;
    max-width: 100%; 
    max-height: 200px;
    min-height: 200px;
    margin-top: 20px;
  }
  .nav-icon{
    width: 30px
  }
  .title-text{
    font-size: 28px;
  }
}
.mobile-logo{
  display: none;
}
@media (min-width: 920px) and (max-width: 1200px) {
  .toggle-nav{
    width: 30%;
  }
  .mobile-logo{
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 920px){
  .toggle-nav{
    width: 32%;
  }
  .nav-icon{
    width: 25px;
  }
  .title-text{
    font-size: 22px;
  }
}
@media(max-width: 480px){
  .toggle-nav{
    width: 45%;
  }
  .ipad-logo{
    display: none;
  }
  .mobile-logo{
    display: block;
  }
  .ipad-toggle{
    display: none;
  }
  .mobile-toggle{
    display: block;
    position: absolute;
    left: 0px;
    top: 8px;
  }
  .nav-icon{
    width: 28px
  }
  .title-text{
    font-size: 16px;
  }
  .invite-buttom{
    padding: 5px 8px;
    font-size: 14px;
  }
}
/* @media (max-width: 480px){
  .card__hover {
    width: 200px;
  }
} */

/* .empty-text{
  
} */