.deal_container {
  font-family: "Inter", sans-serif;
}
.deal_description_title {
  color: rgba(43, 68, 121, 1);
  font-size: 17px;
  font-weight: 500;
}
.deal_description_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-wrap: 200;
}
.more_text {
  color: rgba(56, 114, 227, 1);
  text-decoration: underline;
  padding: 5px;
}
.portfolio_container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin: 20px 1rem 0 0.75rem;
}

.portfolio_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  /* border: 1px solid red; */
}
.portfolio_header_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: rgba(43, 68, 121, 1);
  padding-top: 5px;
}
.line {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.property_card {
  margin-top: 25px;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
}
.property_card_title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  margin-bottom: 12px;
}
.property_card_title > span {
  font-weight: 400;
}

.property_card_right {
  /* border: 1px solid red; */
}
.view_map {
  background: rgba(43, 68, 121, 1);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  /* margin-right: 20px; */
  /* margin-top: 30px; */
  color: white;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 5px;
}
.investor_map {
  height: 100%;
  padding: 20px 10px 10px 12px;
  /* border: 1px solid red; */
}

.cash_flow_card_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;
}

.cash_flow_card {
  width: 47%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 12px 20px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
}
.cash_flow_card_title{
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: rgba(43, 68, 121, 1);
  margin-bottom: 15px;
}
.cash_flow_card_content {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.78px;
  color: rgba(0, 0, 0, 1);
}
@media(max-width:991px){
  .portfolio_header{
    padding-right: 15px;
  }
  .portfolio_header > button{
    height: 35px;
    width: 40px;
    font-size: 15px ;
  }
}