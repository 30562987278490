@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 1rem;
  --font-size-sm: 0.875rem;

  /* Colors */
  --color-tblack: rgba(0, 0, 0, 1);
  --color-twhite: rgba(255, 255, 255, 1);
  --color-tprimary: rgba(43, 68, 121, 1);
  --color-tsecondary: rgba(79, 124, 218, 1);
  --color-tinfo: rgba(178, 203, 255, 1);
  --color-tborder: rgba(217, 217, 217, 1);
  --color-thover: rgba(178, 203, 255, 0.2);

  /* font size */
  --tfs1: 12px;

  /* Gaps */
  --gap-xs: 0.75rem;

  /* Border radiuses */
  --br-5xs: 8px;
}

.invesment__table__wapper {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0 20px 0 5px;
  padding: 0 10px 0 0;
  border-radius: 8px 0 0 8px;
  font-family: var(--font-inter);
  background-color: var(--color-twhite);
  overflow-x: auto;
  position: relative;
}

.invesment__table {
  width: 100%;
  min-width: 800px;
}

.invesment__table__tr {
  padding: 20px 0;
}

.invesment__table__tr > th {
  padding: 30px 0 18px 0;
  border: none;
}

.invesment__table__tr > th:first-child {
  border-radius: 8px 0 0 0px;
}

.last-border-radius {
  border-radius: 0px 0 0 8px;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--color-tinfo);
}

.invesment__table__tr > th:first-child,
.invesment__table__tr > td:first-child {
  width: 150px;
}

.table__text__black {
  color: var(--color-tblack);
  font-size: 14px;
  font-weight: 600;
  line-height: 19.36px;
}

.table__text__primary_1 {
  color: var(--color-tprimary);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 19.36px;
}

.table__text__primary_2 {
  color: var(--color-tprimary);
  font-family: Inter;
  font-size: var(--tfs1);
  font-weight: 600;
  line-height: 16.94px;
}

.table__text__secondary {
  font-size: var(--tfs1);
  font-weight: 500;
  line-height: 16.94px;
  color: var(--color-tsecondary);
}

.tborder1 {
  border: .01px solid var(--color-tborder);
  margin: 0 0px;
  padding: 0;
}

.tborder-div {
  background-color: var(--color-tinfo);
}

.invesment__table__wapper::-webkit-scrollbar {
  width: 5px;
}

.invesment__table__wapper::-webkit-scrollbar-track {
  background: rgba(230, 230, 230, 1);
}

.invesment__table__wapper::-webkit-scrollbar-thumb {
  background: rgba(79, 124, 218, 1);
}

.invesment__table__wapper::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.invesment__table__wapper {
  overflow-x: auto;
}

@media(max-width: 660px) {
  .invesment__table__wapper::-webkit-scrollbar {
    width: 5px;
  }

  .invesment__table__wapper::-webkit-scrollbar-track {
    background: rgba(230, 230, 230, 1);
  }

  .invesment__table__wapper::-webkit-scrollbar-thumb {
    background: rgba(79, 124, 218, 1);
  }

  .invesment__table__wapper::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  .invesment__table__wapper {
    overflow-x: auto;
  }

  .invesment__table__tr > th {
    padding: 18px 0 18px 0;
    border: none;
  }
}