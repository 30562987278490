@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

/* .main_conatiner {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.tables {
  border-collapse: separate;
}
.tables tr {
  text-align: left;
}

.first_conatiner {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: rgb(234 230 230) -5px 6px 8px 0px, rgb(243 240 240) 6px -6px 8px 0px;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.second_conatiner,
.third_conatiner {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: rgb(234 230 230) -5px 6px 8px 0px, rgb(243 240 240) 6px -6px 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.shadow_section::before {
  height: 3em;
  display: table-row;
  content: 1;
}

.shadow_body {
  
  border-top: 1em solid transparent;
  border-radius: 0.25em;

  
  box-shadow: rgb(234 230 230) -5px 6px 8px 0px, rgb(243 240 240) 6px -6px 8px 0px;
  line-height: 3rem;
  background-color: rgb(251 250 255);
}

.shadow_body_bottom {
  border-bottom: 2em solid transparent;
  border-radius: 0.25em;
  box-shadow: rgb(234 230 230) -5px 6px 8px 0px, rgb(243 240 240) 6px -6px 8px 0px;
  line-height: 3rem;
  background-color: rgb(251 250 255);
}

.shadow_body th,
.shadow_body td {
  border: 0 solid #ccc;
}


.shadow_body tr:first-child th:first-child,
.shadow_body tr:first-child td:first-child {
  border-top-left-radius: 0.25em;
}



.shadow_body tr:last-child th,
.shadow_body tr:last-child td {
  border-bottom-width: 1px;
}


.shadow_body tr:last-child th:last-child,
.shadow_body tr:last-child td:last-child {
  border-bottom-right-radius: 0.25em;
}

.tables {
  margin-right: 10px;
  position: relative;
}

.border_line {
  width: 94.5%;
  height: 1px;
  background-color: #d7d7d7;
  position: absolute;
}

.add_gap{
  margin: 18px 0;

} */

.subscription_main_container {
  display: flex;
  /* background-color: #ffffff; */
  margin: 20px 0px 30px 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 0;
}

.custom-margin {
  margin-left: 35px;
}

.subscription_tabel {
  width: 64%;
  /* display: flex; */
}

.subscription_btn {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  padding: 10px 15px 10px 0;
}

.sub_btn {
  padding: 0px 13px 5px;
  background-color: transparent;
  color: rgba(100, 100, 100, 1);
  font-size: 13px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-align: center;
  outline: none;
  border: none;
  width: 10rem;
  line-height: 10.36px;
  transition: 0.1s ease-in-out;
}

.sub_btn:hover {
  color: rgba(43, 68, 121, 1);
}

.sub_btn_active {
  border-bottom: 2px solid rgba(43, 68, 121, 1);
  color: rgba(43, 68, 121, 1);
  margin-top: -8px;
  /* border: 1px solid red; */
}

.invester_index_table {
  display: flex;
  justify-content: space-around;
  margin: 0px;
  padding: 10px;
  align-items: center;
  padding-left: 1.8vw;
  /* background-color: #e9f1fe;
  display: flex;
  justify-content: space-around;
  margin: 0px;
  padding: 10px; */
}

/* .invester_index {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 0px;
} */

.invester_index {
  display: flex;
  flex-direction: column;
  width: 22%;
  justify-content: start;
  margin: 8px 0px;
}

.invester_class {
  font-weight: bold;
  font-size: 14px;
}

.invester_grey {
  background: #d9d9d9;
  padding: 0 8px;
  border-radius: 33px;
  font-size: 12px;
}

/* ALl Class */
.subscription_allclass {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  height: 58vh;
  overflow-y: auto;
}

/* Absoulte */
.relativecls {
  position: relative;
}

.absclass {
  position: absolute;
  top: 20px;
  left: 0;
}

.class_section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 1.8vw;
  /* background-color: #e6ecf9;
  border: 1px solid rgb(205, 205, 205);
  justify-content: space-around;
  align-items: center;
  margin: 8px 0px;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.14); */
}

.class_update_section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 1.8vw;
  padding-top: 4px;
  padding-bottom: 4px;
  /* background-color: #e6ecf9; */
  /* border: 1px solid rgb(205, 205, 205); */
  /* box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.14); */
}

.class_section_div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 8px 0px;
  width: 22%;
}

.class_section_div .c_values {
  /* background-color: white; */
  /* border: 1px solid #cac5c5; */
  /* width: 4vw; */
  /* background: #dbffe3; */
  color: #3d61ae;
  border-radius: 6px;
  height: 2vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 4px;
}

.c_name {
  /* color: #3d61ae; */
  font-weight: bold;
}

.equity_class {
  color: #3d61ae;
  font-weight: bold;
  margin-right: 5px;
  width: 22%;
  display: flex;
  justify-content: start;
  padding-left: 5px;
}

.subscription_container {
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  margin: 10px 21px 0 5px;
  padding: 20px 0px;
  /* border: 1px solid red; */
}

.subscription_main {
  display: flex;
  flex-direction: column;
  /* background-color: #e6ecf9; */
  justify-content: space-around;
  align-items: center;
  margin: 8px 0px 0 0;
  /* box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.14); */
  /* padding: 7px 0; */
  font-size: 12px;
}

.subscription_text {
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  display: inline-block;
  margin: 20px 0;
  color: rgba(0, 0, 0, 1);
}

.subscription_input {
  font-family: Inter, sans-serif;
  padding: 8px 12px;
  border: 1px solid rgba(43, 68, 121, 1);
  border-radius: 4px;
  width: 95%;
  margin: 10px 0 0 15px;
}

.subscription_class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3vh 2vw 3vh 1vw;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* padding: 2vh 3vw; */
  /* box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.sub_icons_a,
.sub_icons_b,
.sub_icons_c {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
}

.rounded_sub_a {
  display: flex;
  height: 15px;
  width: 14px;
  border-radius: 50%;
  background-color: #d8e7fc;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background: rgba(43, 68, 121, 1);
  color: white;
  font-size: 10px
    /* color: #3d61ae; */
    /* height: 30px;
  width: 30px; */
}

.rounded_sub_b {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #dbffe3;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: green;
}

.rounded_sub_c {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #e2e0f4;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #a5a0c7;
}

.sub_amt_text_a {
  padding: 5px 6px;
  color: white;
  background: rgba(79, 124, 218, 1);
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  width: 95%;
  margin: 10px 0 0 15px;
  text-align: center;
  /* border-radius: 5px */
  /* background-color: #d8e7fc; */
  /* color: blue; */
}

.sub_amt_text_b {
  padding: 1px 6px;
  border-radius: 5px;
  background-color: #dbffe3;
  color: green;
  margin: 5px 0;
}

.sub_amt_text_c {
  padding: 1px 6px;
  border-radius: 5px;
  background-color: #e2e0f4;
  color: #a5a0c7;
  margin: 5px 0;
}

.subscription_main_class {
  display: flex;
  width: 100%;
  gap: 20px;
  /* justify-content: ; */
  margin-bottom: 10px;
}

.bold-text {
  font-weight: 800;
  font-size: 16px;
  gap: 2px;
  margin: 10px 0px;
}



.total_sub {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px 0px -2px;
  padding-right: 10px;
  /* padding-left: -5px; */
}


.total_sub_class {
  /* width: 100%; */
  width: 98%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  font-family: inter, sans-serif;
}

@media (max-width: 768px) {
  .bold-text {
    width: 180px;
    margin-top: 18px;
  }

  .total_sub {
    margin-bottom: 20px;
    padding: 20px 0;
  }
}

.total_sub_text {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
}

.subscribe_btn {
  background: rgba(79, 124, 218, 1);
  border-radius: 8px;
  color: white;
  padding: 9px 20px;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 1vw;

  /* background-color: #2b4479; */
}

/* subscription_values */

.main_subscription_graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.14);
  /* padding: 10px 5px; */
  width: 36%;
  border-radius: 8px;
}

.captial_grey {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.subscription_graph {
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin: 8vh 0px 10px 0px;
  padding: 10px;
  height: 100%;
  /* background-color: #e6ecf9; */
  /* margin: 4vh 0px 10px 0px; */
}

.subscription_values {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.subscription_conatiner {
  flex: 1;
  width: 100%;
  background-color: #e6ecf9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.Subscribe_btn {
  margin: 5px 0px 18px 0px;
  width: 100%;
  background-color: #e6ecf9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5vh;
}

.total_terms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 0px;
}

.t_values {
  background-color: white;
  width: 12vh;
  text-align: center;
  border: 1px solid #cac5c5;
}

/* WaterfAll Steps */
.waterfall_steps {
  margin-right: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cash_flow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.mtt_10 {
  margin-top: 10rem;
}

.class_flow {
  /* background-color: #fbfaff; */
  border-radius: 7px;
  border: 1px solid black;
  width: auto;
  color: black;
  padding: 12px;
  text-align: center;
  /* box-shadow: -2px 1px 4px #8080808c; */
}

/* Tabel Graph */
.resposive_tabel_graph {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 1vw;
  position: relative;
  /* margin: 0 3vw; */
}

/* .graph_values {
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  gap: 2px;
} */

.graph_values {
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  transform-origin: top left;
  /* transform: rotateY(10deg) skewY(-22deg); */
  transform: rotateY(10deg) skewY(-12deg);
}

.cube_left {
  width: 60px;
  height: 100%;
  background: #b2cbff;
  transform-origin: right;
  transform: skewY(44deg) translateZ(20px) rotateY(-42deg);
}

.front-view {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-right: 1vw;
}

.cube_top {
  top: -110px;
  position: absolute;
  height: 110px;
  display: flex;
  width: 80px;
  background: #2b4479;
  transform: translateX(63px) rotateX(65deg) rotateZ(-24deg);
  transform-origin: bottom left;
  /* top: -86px; */
  /* height: 90px; */
  /* width: 90px; */
  /* transform: translateX(64px) rotateX(50deg) rotateZ(-32deg); */
}

.debt_grap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  background-color: #2b4479;
  color: white;
  padding: 0.6rem;
  position: relative;
  font-size: 10px;
}

.scaler_class {
  position: absolute;
  color: #6c757d;
  font-size: 10px;
  font-weight: 700;
  left: -39px;
  top: -9px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scaler_class_last {
  position: absolute;
  color: #6c757d;
  font-size: 10px;
  font-weight: 700;
  left: 29px;
  top: -18px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  background-color: #6c757d;
  height: 1px;
  width: 12px;
}

.sub-btn {
  /* height: 25px; */
  /* width: 25px; */
  height: 38px;
  width: 38px;
  padding: 4px;
  border-radius: 50%;
  margin: 0px 5px 0 0px;
}

.sub-btn_a {
  color: white;
  background: rgba(79, 124, 218, 1);
  /* color: #2f80ed; */
  /* background-color: #d8e7fc; */
}

.sub-btn_b {
  color: white;
  background: rgba(79, 124, 218, 1);
  /* background-color: #dbffe3;
  color: green; */
}

.sub-btn_c {
  color: white;
  background: rgba(79, 124, 218, 1);
  /* background-color: #e2e0f4;
  color: #a5a0c7; */
}

/* Resposive */
@media (max-width: 768px) {
  .subscription_main_container {
    flex-direction: column;
  }

  .main_subscription_graph {
    width: 100%;
  }

  .subscription_tabel {
    width: 100%;
  }

  .subscription_graph {
    width: 100%;
    flex-direction: column;
    padding: 0;
    padding-top: 23px;
  }

  .graph_values {
    flex-direction: row;
    transform-origin: top;
    transform: translate(20px, 10px);
    width: 100%;
  }

  .cube_top {
    transform: translateY(153px) rotateY(-64deg) translateX(-20px) rotateZ(6deg) skewX(5deg) skewY(18deg);
    background: #2b4479;
    transform-origin: top;
    width: 63px;
    height: 60px;
    position: absolute;
  }

  .front-view {
    flex-direction: column;
    padding: 15px;
  }

  .cube_left {
    background: #b2cbfd;
    width: 99.4%;
    height: 27px;
    transform-origin: top;
    transform: translate(-5px, 10px) skewX(45deg);
  }

  .scaler_class {
    flex-direction: row-reverse;
    left: -7px;
    top: -14px;
  }

  .indicator {
    transform: rotate(90deg);
  }

  .subscription_main_class {
    width: auto;
    flex-direction: column;
  }

  .subscription_btn {
    justify-content: space-evenly;
    gap: 0;
  }

  .sub_btn {
    line-height: 15px;
  }

  .total_sub_class {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .resposive_tabel_graph {
    margin-left: 0;
  }

  .waterfall_steps {
    padding: 20px 0;
    gap: 23px;
  }

  .mtt_10 {
    margin-top: 0;
  }

  .invester_class {
    font-size: 10px;
  }

  .invester_value {
    font-size: 9px;
  }
}
.invesment_tbody{
  font-size: 14px;
  font-weight: 400; 
  font-family:"inter, san-serif";
  letter-spacing:.3px
 }
/* For medium-sized devices */
@media (min-width: 768px) and (max-width: 1050px) {
  .subscription_main_class {
    width: auto;
    flex-direction: column;
  }

  .subscription_btn {
    justify-content: space-evenly;
    gap: 0;
  }

  /* .sub_btn {
    padding: 9px;
    font-size: 9px;
    width: 7rem;
  } */

  /* Add specific styles for medium-sized devices here */
}

/* For larger devices */
@media (min-width: 1024px) {
  /* Add specific styles for larger devices here */
}

@media(max-width: 991px){
  
.subscription_main_container {
  padding: 0 10px;
}
.subscription_text {
  color:rgba(43, 68, 121, 1);
}
.subscription_container {
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  margin: 30px 0px 0 0px;
  padding: 0px 0px;
  
  /* border: 1px solid red; */
}
.subscription_main_class{
  width: 98%;
}
.subscription_class{
  padding: 20px 15px 20px 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  
}
.sub_icons_a{
  font-size: 15px;
  font-weight: 500;
}
.rounded_sub_a{
  padding: 8px;
  font-size: 12px;
}
.subscription_input{
  font-size: 16px;
  color:rgba(43, 68, 121, 1);
}
.sub_amt_text_a{
  padding: 10px 0;
}
.total_sub{
  justify-content: flex-start;
  padding: 10px 0;
  
}
.total_sub_class{
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

}
.total_sub_text{
  margin-top: 15px;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  ;
}
.button_div{
  width: 70%;

}
.subscribe_btn{
  font-size: 15px;
  margin-top: 20px;
}
.waterfall_steps{
  font-size: 16px;
}
.class_flow > h4 {
  font-size: 14px !important;
  font-weight: 600;
}
.class_flow > h5 {
  font-size: 14px !important;
  font-weight: 600;
}
.cash_flow > h2{
  font-weight: 500;
  font-size: 15px !important;
}
.invesment_table{
  font-size: 15px ;
  font-weight: 600;
  width: 900px;
}
.invesment_result_card{
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin: 0px 5px  0 0 ;
}
.invesment_result_card::-webkit-scrollbar {
  width: 5px;
}

.invesment_result_card::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.invesment_result_card::-webkit-scrollbar-thumb {
  background: rgba(79, 124, 218, 1);
}

.invesment_result_card::-webkit-scrollbar-thumb:hover {
  background: rgba(79, 124, 218, 1);
}
.invesment_table > thead > tr{
  font-weight: 600 !important;
}
.invesment_table > tbody > td{
  font-weight: 600 !important;
}
.invesment_tbody{
 font-size: 14px;
 font-weight: 600; 
 font-family: Inter;
}
.investment_tr > th{
  font-weight: 600 !important;
}
.investment_tr > td{
  font-weight: 600 !important;
}
}