#notes {
  font-family: "Poppins";
  width: 100%;
  word-wrap: break-word;
  display: none;
}

.slider {
  background-color: transparent;
  display: flex;
  width: 100%;
  height: 56px;
  padding-right: 20px;
}

.tag {
  border-left-width: 0.1em;
  border-left-style: solid;
  border-left-color: white;
  border-right-width: 0.1em;
  border-right-style: solid;
  border-right-color: white;
  box-sizing: border-box;
  padding: 0.5em;
  position: relative;
  text-align: center;
}

.tag-text {
  color: black;
  display: block;
  font-family: "Poppins";
  font-weight: 600;
  overflow: hidden;
  user-select: none;
  font-size: 12px;
}

.tag:first-of-type {
  border-radius: 4px 0px 0px 4px;
}

.tag:last-of-type {
  border-radius: 0px 4px 4px 0px;
}

.slider-thumb {
  align-items: center;
  border-radius: 2em;
  bottom: 0;
  cursor: ew-resize;
  display: flex;
  height: 2em;
  justify-content: center;
  width: 2em;
  position: absolute;
  right: calc(-1.7em);
  top: -20px;
  user-select: none;
  z-index: 10;
}

.slider-thumb span {
  font-size: 1.5em;
  font-weight: 500;
  color: black;
}

.tag span:nth-child(2) {
  font-weight: 500 !important;
}

.slider-thumb span {
  position: absolute;
  top: -5px;
  font-size: 10px;
  right: 125%;
}

.tag-text.tag-value {
  font-weight: 500;
}

.slider .tag:nth-child(2) .dark-box {
  background-color: #2f80ed;
}

.slider .tag:nth-child(3) .dark-box {
  background-color: #009d55;
}

.slider .tag:nth-child(4) .dark-box {
  background-color: #eb5757;
}

.main-div {
  height: 100%;
}

.tag-name {
  font-weight: 500;
}
