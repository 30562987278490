html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  background: #fbfaff;
}

body {
  background: #fbfaff;
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-size: 14px;
  color: #777777;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 600;
}

p {
  line-height: 1.6;
  margin-bottom: 10px;
}

label {
  vertical-align: middle;
}

* {
  outline: none !important;
}

a {
  color: #4c7cf3;
  text-decoration: none;
}
a:hover {
  color: #346bf1;
  outline: 0;
  text-decoration: none;
}
a:active {
  color: #346bf1;
  outline: 0;
  text-decoration: none;
}
a:focus {
  color: #346bf1;
  outline: 0;
  text-decoration: none;
}

code {
  color: #4c7cf3;
}

.xp-leftbar {
  width: 250px;
  height: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 9;
  transition: all 0.3s ease;
  background: #ffffff;
  border-right: 1px solid #e5e5e5;
}

.xp-logobar {
  margin-bottom: 15px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px;
  border-bottom: 1px solid #e5e5e5;
}

.xp-logo {
  font-weight: 600;
  font-size: 20px;
  line-height: 121.4%;
  color: #3e4756;
  text-transform: uppercase;
  display: flex;
}

.xp-navigationbar {
  height: calc(100vh - 100px);
  overflow: auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.xp-rightbar {
  margin-left: 250px;
  overflow: hidden;
  min-height: 500px;
  transition: all 0.3s ease;
}

.xp-contentbar {
  padding: 30px;
  margin-bottom: 30px;
}

.xp-footerbar {
  position: absolute;
  right: 0;
  left: 250px;
  background-color: #f5f9ff;
  color: #353b48;
  text-align: center;
  padding: 20px 30px;
}

.xp-toggle-menu .xp-footerbar {
  left: 0;
}

/* ::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
} */
/* ----- Icons ----- */
.xp-icon-box div {
  color: #777777;
  border-radius: 50px;
  line-height: 38px;
  white-space: nowrap;
}
.xp-icon-box div p {
  margin-bottom: 0;
  line-height: inherit;
}
.xp-icon-box div p i {
  display: inline-block;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 12px;
  margin-left: -14px;
  margin-bottom: 1px;
  margin-top: 1px;
}
.xp-icon-box div p span.text-alias {
  color: #d4d4d4;
}
.xp-icon-box div:hover {
  color: #ffffff;
  background-color: #4c7cf3;
}
.xp-icon-box div:hover p i {
  color: #4c7cf3;
}
.xp-icon-box div:hover p span.text-alias {
  color: #ffffff;
}
/* 
============
    Menu
============
*/
.xp-vertical-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.xp-vertical-menu > li {
  position: relative;
  margin: 0;
  padding: 0px 12px 8px 0px;
}
.xp-vertical-menu > li > a {
  padding: 10px 25px;
  color: #777777;
  display: flex;
  align-items: center;
  height: 40px;
}
.xp-vertical-menu > li > a > i {
  display: flex;
  width: auto;
  font-size: 16px;
  vertical-align: middle;
}
.xp-vertical-menu > li > a > span {
  vertical-align: middle;
  margin-left: 8px;
}
.xp-vertical-menu > li:hover > a,
.xp-vertical-menu > li.active > a {
  color: #4c7cf3;
  background: #e8f2ff;
  border-radius: 0px 10px 10px 0px;
}
.xp-vertical-menu > li .label,
.xp-vertical-menu > li .badge {
  margin-top: 3px;
}
.xp-vertical-menu li.xp-vertical-header {
  padding: 14px 25px;
  font-size: 12px;
}
.xp-vertical-menu li > a > .mdi-chevron-right {
  width: auto;
  height: auto;
  padding: 0;
}
.xp-vertical-menu li.active > a > .mdi-chevron-right {
  transform: rotate(-270deg);
}
.xp-vertical-menu li.active > .xp-vertical-submenu {
  display: block;
}
.xp-vertical-menu a {
  color: #777777;
  text-decoration: none;
}
.xp-vertical-menu .xp-vertical-submenu {
  display: none;
  list-style: none;
  padding-left: 5px;
  margin: 0 1px;
  background: rgba(13, 13, 13, 0);
}
.xp-vertical-menu .xp-vertical-submenu .xp-vertical-submenu {
  padding-left: 20px;
}
.xp-vertical-menu .xp-vertical-submenu > li > a {
  padding: 8px 25px 8px 45px;
  display: block;
  font-size: 14px;
  color: #777777;
}
.xp-vertical-menu .xp-vertical-submenu > li > a > .fa {
  width: 20px;
}
.xp-vertical-menu .xp-vertical-submenu > li > a > .mdi-chevron-right,
.xp-vertical-menu .xp-vertical-submenu > li > a > .mdi-chevron-down {
  width: auto;
}
.xp-vertical-menu .xp-vertical-submenu > li.active > a,
.xp-vertical-menu .xp-vertical-submenu > li > a:hover {
  color: #4c7cf3;
}

.xp-vertical-menu-rtl {
  list-style: none;
  margin: 0;
  padding: 0;
}
.xp-vertical-menu-rtl > li {
  position: relative;
  margin: 0;
  padding: 0;
}
.xp-vertical-menu-rtl > li > a {
  padding: 10px 25px;
  display: block;
  color: #777777;
}
.xp-vertical-menu-rtl > li > a > i {
  display: inline-block;
  width: 25px;
  font-size: 16px;
  vertical-align: middle;
}
.xp-vertical-menu-rtl > li > a > span {
  vertical-align: middle;
}
.xp-vertical-menu-rtl > li:hover > a,
.xp-vertical-menu-rtl > li.active > a {
  color: #4c7cf3;
  background: #dbe5fd;
}
.xp-vertical-menu-rtl > li .label,
.xp-vertical-menu-rtl > li .badge {
  margin-top: 3px;
}
.xp-vertical-menu-rtl li.xp-vertical-header {
  padding: 10px 15px 10px 25px;
  font-size: 12px;
}
.xp-vertical-menu-rtl li > a > .mdi-chevron-right {
  width: auto;
  height: auto;
  padding: 0;
}
.xp-vertical-menu-rtl li.active > a > .mdi-chevron-right {
  transform: rotate(-270deg);
}
.xp-vertical-menu-rtl li.active > .xp-vertical-submenu {
  display: block;
}
.xp-vertical-menu-rtl a {
  color: #777777;
  text-decoration: none;
}
.xp-vertical-menu-rtl .xp-vertical-submenu {
  display: none;
  list-style: none;
  padding-right: 5px;
  margin: 0 1px;
  background: rgba(13, 13, 13, 0);
}
.xp-vertical-menu-rtl .xp-vertical-submenu .xp-vertical-submenu {
  padding-right: 20px;
}
.xp-vertical-menu-rtl .xp-vertical-submenu > li > a {
  padding: 8px 45px 8px 25px;
  display: block;
  font-size: 14px;
  color: #777777;
}
.xp-vertical-menu-rtl .xp-vertical-submenu > li > a > .fa {
  width: 20px;
}
.xp-vertical-menu-rtl .xp-vertical-submenu > li > a > .mdi-chevron-right,
.xp-vertical-menu-rtl .xp-vertical-submenu > li > a > .mdi-chevron-down {
  width: auto;
}
.xp-vertical-menu-rtl .xp-vertical-submenu > li.active > a,
.xp-vertical-menu-rtl .xp-vertical-submenu > li > a:hover {
  color: #4c7cf3;
}

/* 
==================
    Topbar
==================
*/
.xp-toggle-menu .xp-leftbar {
  position: fixed;
  width: 100px;
  /* transition: all 0.3s ease; */
}
.xp-toggle-menu .xp-rightbar {
  margin-left: 0px;
}
.xp-toggle-menu .xp-menubar img {
  transform: rotate(180deg) !important;
}

.xp-topbar {
  background-color: #ffffff;
  padding: 15px 30px;
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
}
.xp-topbar .dropdown-toggle::after {
  display: none;
}
.xp-topbar .xp-badge-up {
  position: relative;
  top: -15px;
  margin: 0 -10px;
}
.xp-topbar .xp-searchbar input[type="search"] {
  background-color: #4a5263;
  color: #ffffff;
  padding-left: 20px;
  border: none;
  border-radius: 50px 0 0 50px;
}
.xp-topbar .xp-searchbar .btn {
  background-color: #4a5263;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0 50px 50px 0;
  padding: 4px 15px;
}
.xp-topbar .xp-userprofile a img {
  width: 40px;
}
.xp-topbar .xp-userprofile a .xp-user-live {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #353b48;
  background-color: #4c7cf3;
}
.xp-topbar .xp-userprofile .dropdown-menu {
  top: 15px !important;
  padding: 10px;
  background-color: #f9fafc;
  border: 1px solid #e8ecf4;
}
.xp-topbar .xp-userprofile .dropdown-menu a:hover {
  color: #4c7cf3;
  background-color: #dbe5fd;
  border-radius: 3px;
}
.xp-topbar .xp-notification .dropdown-menu {
  width: 380px;
  top: 15px !important;
  background-color: #fff;
  border: 1px solid #e8ecf4;
}
.xp-topbar .xp-notification li.media.xp-noti {
  padding: 12px 24px;
  background-color: #fcfcfc;
  border-radius: 3px;
  margin-top: 7px;
  margin-bottom: 7px;
  border: none;
  display: flex;
  justify-content: space-between;
}
.media .media-body {
  padding: 12px 24px;
  border-bottom: 1px solid #c4c4c4;
}
.media-footer {
  padding: 12px 24px;
  border-top: 1px solid #c4c4c4;
}
.xp-topbar .xp-notification li.media.xp-noti a h5 {
  color: #555555;
  font-size: 18px !important;
  margin-bottom: 0px !important;
}
.xp-topbar .xp-notification li.media.xp-noti a h5 span {
  color: #777777;
}
.xp-topbar .xp-notification li.media.xp-noti a p {
  color: #777777;
}
.xp-topbar .xp-notification li.media.xp-noti .xp-noti-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #dbe5fd;
  color: #4c7cf3;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}
.xp-topbar .xp-notification li.media.xp-noti:hover {
  background-color: #dbe5fd;
}
.xp-topbar .xp-notification li.media.xp-noti:hover a h5 {
  color: #4c7cf3;
}
.xp-topbar .xp-notification li.media.xp-noti:hover a h5 span {
  color: #4c7cf3;
}
.xp-topbar .xp-notification li.media.xp-noti:hover a p {
  color: #4c7cf3;
}
.xp-topbar .xp-notification li.media.xp-noti:hover .xp-noti-icon {
  background: #ffffff;
}
.xp-topbar .xp-message .dropdown-menu {
  width: 310px;
  padding: 10px;
  top: 15px !important;
  background-color: #f9fafc;
  border: 1px solid #e8ecf4;
}
.xp-topbar .xp-message li.media.xp-msg {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  margin-top: 7px;
  margin-bottom: 7px;
  border: none;
  box-shadow: 0 0 30px 0 rgba(200, 200, 200, 0.2);
}
.xp-topbar .xp-message li.media.xp-msg a h5 {
  color: #555555;
}
.xp-topbar .xp-message li.media.xp-msg a h5 span {
  color: #777777;
}
.xp-topbar .xp-message li.media.xp-msg a p {
  color: #777777;
}
.xp-topbar .xp-message li.media.xp-msg .xp-noti-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #dbe5fd;
  color: #4c7cf3;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}
.xp-topbar .xp-message li.media.xp-msg:hover {
  background-color: #dbe5fd;
}
.xp-topbar .xp-message li.media.xp-msg:hover a h5 {
  color: #4c7cf3;
}
.xp-topbar .xp-message li.media.xp-msg:hover a h5 span {
  color: #4c7cf3;
}
.xp-topbar .xp-message li.media.xp-msg:hover a p {
  color: #4c7cf3;
}
.xp-topbar .xp-message li.media.xp-msg:hover .xp-noti-icon {
  background: #ffffff;
}

.xp-breadcrumbbar {
  background-color: #353b48;
  padding: 15px 30px;
}
.xp-breadcrumbbar .page-title {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 0;
}
.xp-breadcrumbbar .breadcrumb .breadcrumb-item a {
  color: #777777;
}
/* 
==================
    Responsive
==================
*/
@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}
@media (max-width: 768px) {
  .xp-rightbar {
    margin-left: 0;
  }
}
@media (max-width: 1200px) {
  body {
    overflow-x: hidden;
  }

  .xp-leftbar {
    position: fixed;
    left: -250px;
  }

  .xp-toggle-menu .xp-leftbar {
    position: fixed;
    left: 0px;
  }

  .xp-rightbar {
    margin-left: 0 !important;
  }

  .xp-menubar {
    text-align: right;
    right: -28px !important;
  }

  .xp-searchbar {
    margin-top: 20px;
  }

  .xp-footerbar {
    left: 0;
  }

  .xp-email-rightbar .email-open-box .open-email-head ul {
    text-align: left;
    margin-top: 15px;
  }
}

/*# sourceMappingURL=style.css.map */
.xp-menubar {
  text-align: right;
  width: 22px;
  height: 22px;
  background: #f4f5f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: -10px;
}
.xp-menu-hamburger {
  display: flex;
  align-items: center;
}
.xp-toggle-menu .toggle-span {
  display: none;
}
.xp-toggle-menu .xp-rightbar {
  margin-left: 100px;
}
.top-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 121.4%;
  color: #3e4756;
  margin-bottom: 0px;
}
.card.profile-card {
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
}
.profile_card_img {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: -60px;
}
.dropdown-menu.profile-menu a.dropdown-item {
  padding: 8px 0px !important;
}
.dropdown-menu.profile-menu a.dropdown-item:hover {
  color: #2f80ed;
  background-color: inherit;
}
.dropdown-menu.profile-menu {
  border: none;
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 6px 15px rgb(0 0 0 / 10%);
  border-radius: 4px;
  width: 200px;
}
.card.dash-card {
  background: #ffffff;
  border-radius: 10px;
  border: none;
}
.card.dash-card .card-body {
  padding: 14px 22px;
}
@media only screen and (max-width: 1210px) {
  #card-body-flex {
    flex-wrap: wrap;
    justify-content: space-between !important;
    row-gap: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .xp-contentbar {
    padding: 16px;
  }
}

/* 
==================
    Buttons
==================
*/

button.btn:focus {
  box-shadow: none !important;
}
a.btn:focus {
  box-shadow: none !important;
}
/* 
==================
    Fonts
==================
*/
.fs-7 {
  font-size: 7px;
}
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-40 {
  font-size: 40px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt--2 {
  margin-top: -30px !important;
  margin-bottom: 10px !important;
}

.sponser-index-card.card.data-card .card-body .data-body {
  margin-top: 8px;
}

.ce-cash-card {
  margin-top: 6px !important;
}

.open-deals td,
.closed-deals td {
  padding: 0.5px !important;
}

/* 
==================
    Font Weights
==================
*/
.fw-4 {
  font-weight: 400;
}
.fw-6 {
  font-weight: 600;
}
.fw-5 {
  font-weight: 500;
}

/* 
==================
    Margins
==================
*/

.mb-36 {
  margin-bottom: 36px;
}

/* 
==================
    Font Colors
==================
*/
.text-black {
  color: #3e4756;
}
.text-white {
  color: #ffffff;
}
.text-muted {
  color: #7e8a9d !important;
}
.text-blue {
  color: #2f80ed;
}
.text-green {
  color: #009d55;
}
.text-red {
  color: #cc0000;
}
.text-yellow {
  color: #fe7f00;
}
.bg-grey {
  background-color: #a2acbd;
}
.text-dark-blue {
  color: #9b51e0;
}
.text-tomato {
  color: #e64c24;
}
.z-2 {
  z-index: 2;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 125px;
  height: 1.2em;
  white-space: nowrap;
}

/******************************/

div.profile-card {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
}
div.profile-card #card-bg {
  background: linear-gradient(180deg, #8bb0ff -6.2%, #2b4479 115.17%);
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 200px;
}
.profile-data {
  background-color: #fff;
  height: 150px;
  padding: 0px 24px;
}
.profile-img {
  display: flex;
}
.profile-img .user-img {
  width: 170px;
  height: 174px;
  border-radius: 100%;
  border: 7px solid #fff;
  object-fit: cover;
  margin-top: -70px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
}
.company-profile-data {
  background-color: #fff;
  height: 100px;
  padding: 0px 24px;
}
.company-img {
  display: flex;
}
.company-img .company-user-img {
  width: 150px;
  height: 120px;
  /* border-radius: 100%; */
  border: 7px solid #fff;
  object-fit: cover;
  margin-top: -142px;
  /* box-shadow: 0px 2px 4px rgb(0 0 0 / 8%); */
  margin-left: 10px;
  padding: 1px;
}
.company-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #344050;
  margin-left: 15px;
}
.compnay-mail {
  margin-top: -8px;
  font-size: 18px;
  margin-left: 15px;
}
.update-logo {
  font-size: 16px;
}
.user-title {
  margin-left: 32px;
  margin-top: 42px;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #344050;
}
.card.data-card {
  background: #ffffff;
  border: 1px solid #f4f5f6;
  box-sizing: border-box;
}
.card.data-card .card-body {
  padding: 12px 21px;
}
.card.data-card .card-body .data-header {
  background-color: #e4eefa;
  margin: -12px -21px;
  padding: 12px 21px;
}
.card.data-card .card-body .data-body {
  margin-top: 30px;
}
.data-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #404756;
}
.divider {
  margin: 12px -21px;
  border-bottom: 1px solid #f2f2f2;
}
#submit-btn button.btn {
  width: 107px;
  height: 48px;
}
.btn.edit-btn {
  /* border: 1px solid #2f80ed; */
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: -4px;
}
.btn.delete-btn {
  /* border: 1px solid #eb5757; */
  box-sizing: border-box;
  border-radius: 4px;
}
span.badge.table-badge {
  padding: 4px 12px;
  line-height: normal;
}
table.table.data-table thead {
  border-style: none;
}
.bg-green {
  background: #009d55;
}
.bg-orange {
  background: #eb5757;
}
.hero {
  background: white;
  min-height: 250px;
  padding: 15px;
}
.hero_images img {
  width: 100%;
  min-height: 200px;
}
.property_specs {
  min-height: 100px;
  padding: 10px;
}

#invitation-modal .modal-content {
  border-radius: 10px;
  border: none;
}
#invitation-modal .modal-content .modal-header {
  background: #e8eff7;
  border-radius: 10px 10px 0px 0px;
}
#invitation-modal .modal-content .modal-body {
  padding: 24px;
}
#invite-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #2f80ed;
  margin: 0px auto;
}

.modal-header .btn-close {
  padding: 0px;
  margin: 0px;
}
input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #a2acbd;
}
label.form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #56585d;
}
#description {
  height: 80%;
}
label.id-label {
  cursor: pointer;
  width: 100%;
  height: 80%;
}
#id_card {
  display: none;
}
#travel-documents {
  display: none;
}
.d-flex.upload-style {
  border: 1px dashed rgba(82, 87, 92, 0.9);
  border-radius: 5px;
  padding: 20px 36px;
}
.btn.add-new-btn {
  margin-top: 30px;
  color: #2f80ed;
}
.data-flow {
  background-color: #fcfcfc;
  margin: -12px -21px;
  padding: 12px 21px;
}
.form-control.table-control {
  border: none;
  border-bottom: 1px solid #969696;
  border-radius: 0px;
  padding: 0px;
  width: fit-content;
  max-width: 100px;
  background-color: transparent;
}
.form-control.table-control:focus {
  box-shadow: none;
}
.form-control.table-control::placeholder {
  font-size: 10px;
  color: #dadada;
}
.dark-box {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #333333;
}
.danger-box {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #eb5757;
}
.success-box {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #009d55;
}
.primary-box {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #2f80ed;
}
.progress.prog {
  background-color: #f5f9ff;
  margin-top: 16px;
  border-radius: 1px;
  height: 56px;
}
.progress.prog .progress-bar {
  border-right: 1px solid #a1c9ff;
}
.cash-div {
  background: #fbfaff;
  border-radius: 3px;
  padding: 8px 4px;
  width: 100%;
  text-align: center;
}
.card.inv-card {
  background: #ffffff;
  border: 1px solid #f4f5f6;
  box-sizing: border-box;
}
.sub-card::after {
  content: "";
  border-right: 1px solid #dadada;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
table#metrics-table tr,
td {
  padding: 4px 8px !important;
}
.single-item img {
  border-radius: 5px;
  height: 250px;
}
.copy-item img {
  width: 100%;
  height: 70px;
}
.slick-track {
  margin-left: auto;
  margin-right: auto;
}
.btn.cross-btn {
  position: absolute;
  right: 15px;
  margin-top: -67px;
}

.sponser-profile-table .del-btn {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .xp-rightbar {
    margin-left: 0 !important;
  }
}
/******signup***********/
#signup-bg {
  background-image: url("../images/backGroundImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.vh-height-100 {
  min-height: 100vh !important;
}
.vh-height-94 {
  min-height: 94vh !important;
}
.signup-col-1 {
  padding: 54px 72px 54px 72px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.signup-col-2 {
  padding: 0px 100px 0px 100px;
}
.sign-up-form {
  margin-top: 40px;
}
.col-form-label.signup-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 13px;
  color: #3e4756;
}
label.form-label.signup-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 13px;
  color: #3e4756;
}
.form-check-label.signup-check-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 13px;
  color: #3e4756;
}
.form-text.signup-form-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #a2acbd;
}
.form-control.sign-up-control:focus {
  box-shadow: none;
}
.form-control.sign-up-control {
  height: 52px;
}
.form-control.text-control:focus {
  box-shadow: none;
  border-color: #86b7fe;
}
.form-control.signature-control {
  border: 1px solid #a2acbd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
}
.form-control.signature-control:focus {
  box-shadow: none;
  border-color: #0d6efd;
}
.form-control.after-signature-control {
  background: #f2f2f2;
  border-radius: 4px;
  border: none;
  padding: 20px;
}
.btn-lg {
  padding: 8px 1rem;
  border-radius: 0.3rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.form-select.signup-select {
  height: 52px;
}
.form-select.signup-select:focus {
  box-shadow: none;
}

@media only screen and (max-width: 1024px) {
  .signup-col-2 {
    padding: 32px 24px;
  }
  .signup-col-1 {
    padding: 54px 24px;
  }
}
@media only screen and (max-width: 450px) {
  .row.signup-row {
    flex-direction: column;
  }
  .signup-col-2 {
    padding: 32px 24px;
  }
}
/********** Chat page css ***********/

.chat-box {
  background: white;
  /* min-height: 881px; */
  height: 100%;
  padding: 0px;
  width: 100%;
}
.chat-header {
  min-height: 55px;
  height: auto;
  background: white;
  /* border-bottom: 1px solid #d8d9eb; */
  color: black;
}

.chat-header .sender-name {
  padding: 0px 15px 10px 0px;
}

.sender-name > p {
  line-height: 1.4;
}

.sender-name .user-status {
  /* font-family: "Open Sans"; */
  color: #b6c2d2;
}
.main-chat-div {
  /* min-height: 810px; */
  height: 100vh;
  width: 100%;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.receiver-msg {
  min-height: 35px;
  height: auto;
  padding: 10px;
  max-width: 70%;
  width: auto;
  border-radius: 4px;
  background: #edf2f8;
}

.reciever-img img {
  border-radius: 50%;
  margin-right: 10px;
}

.reciever-time,
.sender-time {
  color: #b6c2d2;
  margin-left: 40px;
}

.reciever-div {
  display: flex;
  align-items: center;
}

.sender-div {
  display: flex;
  justify-content: end;
}

.sender-msg {
  min-height: 35px;
  height: auto;
  padding: 10px;
  max-width: 70%;
  width: auto;
  border-radius: 4px;
  color: white;
  background: #2a7be4;
}

.chat-footer {
  height: 66px;
  width: 385px;
  padding: 20px;
  padding-left: 20px;
  position: fixed;
  bottom: 0;
}

.message-pad {
  height: 46px;
  width: 75%;
  background: #e9e9e9;
  border-radius: 40px;
}

.invite-btn,
.invited-btn {
  height: 26px;
  width: 90px;
  background: #2f80ed;
  border: none;
  outline: none;
  border-radius: 20px;
  color: white;
}

.invited-btn {
  background: #009d55;
}

.message-pad input {
  height: 100%;
  width: 100%;
  border-radius: 40px;
  outline: none;
  border: none;
  background: #e9e9e9;
  padding-left: 20px;
}

.pad-icons {
  width: 25%;
}

.stack-ul .nav-link {
  color: #828282;
}

.stack-ul .nav-pills .nav-link.active {
  color: black;
  background-color: none;
}
.stack-ul.deals-role {
  border-bottom: 4px solid #e5e5e5;
  position: relative;
  height: 40px;
}
.stack-ul.deals-role .nav-link.active {
  color: black;
  font-weight: bold;
  background-color: inherit;
  border-bottom: 4px solid green !important;
}
.card.table-card {
  background: #ffffff;
  border: 1px solid #f4f5f6;
  box-sizing: border-box;
}
.btn.action-btn.dropdown-toggle::after {
  display: none;
}
h2.total-shares {
  font-size: 16px;
  line-height: 20px;
  color: rgba(86, 88, 93, 0.8);
  margin-bottom: 0px;
}
#deal-prog {
  height: 24px;
}
.deals_shares {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
}
.deals-data {
  font-weight: 600;
  font-size: 18px;
  color: #56585d;
}

.deals-subdata {
  font-weight: 600;
  font-size: 16px;
  color: #56585d;
}

.stack-ul.data-deal-role {
  border-bottom: 4px solid #e5e5e5;
  position: relative;
  height: 40px;
}
.stack-ul.data-deal-role .nav-link.active {
  color: #2f80ed;
  font-weight: bold;
  background-color: inherit;
  border-bottom: 4px solid #2f80ed !important;
}

.clr-btn {
  background: #d8e2ee;
  border: none;
  outline: none;
  color: #333333;
  font-weight: 500;
  font-family: "Poppins";
}

.copy-link,
.save-btn,
.invite2-btn {
  width: 137px;
  height: 40px;
  background: #c4c4c4;
  color: white;
  outline: none;
  border: none;
}

.save-btn {
  background: #009d55;
}

.invite2-btn {
  background: #2f80ed;
  width: 189px;
  margin-left: 15px;
  padding-top: 8px;
}

.progress-bar.subscribed-bar,
.progress-bar.shares-bar {
  border-radius: 100px;
}

#deal-prog {
  position: relative;
}

.progress-bar.subscribed-bar {
  position: absolute;
  height: 100%;
  z-index: 1;
}

.progress-bar.shares-bar {
  position: absolute;
  height: 100%;
  right: 0;
  width: 52% !important;
}

/* .table>:not(caption)>*>* {
    box-shadow: inset 0 0 0 9999px#F4F5F6 !important;
} */

.invester-index-table td {
  padding: 6px 8px !important;
  font-size: 16px;
}

.investors-tabs button {
  padding: 8px 8px 8px 1px;
}

td {
  padding: 10px 8px !important;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: white !important;
}

.filters {
  width: 273px;
  min-height: 21px;
  height: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: start;
}

.filter-bar {
  height: 21px;
  width: 160px;
  background: white;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.filter-bar a {
  text-decoration: none;
}

.filter-bar .col-auto {
  height: 25px;
}

.filter-bar a.active {
  background: #012658;
  padding: 2px 8px 2px 8px;
  color: white;
  height: 17px;
  border-radius: 10px;
}

.investor-filters {
  outline: none;
  border: none;
  border-radius: 20px;
  padding-left: 10px;
}

.offcanvas-body {
  padding: 0rem 1rem !important;
}

.bg-orange {
  background: #e1512b;
}

.text-orange {
  color: #e1512b !important;
}

.text-green {
  color: #009d55 !important;
}

.text-red {
  color: #eb5757 !important;
}

.text-blue {
  color: #2f80ed !important;
}

.investor-detail {
  height: 22px;
  width: 88px;
}

.investor-detail button {
  padding: 0px 10px 0px 10px;
}

.offcanvas-header {
  border-bottom: 1px solid #d8d9eb;
  padding: 1rem 1rem 0rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 0.5rem;
}

table th {
  vertical-align: middle;
}

.xp-vertical-menu > li.active > a svg path {
  fill: #4c91f6;
}

.xp-vertical-menu li:hover a svg path {
  fill: #4c91f6;
}

.offcanvas.show {
  height: 100vh;
}

.sponser-percentage {
  display: flex;
  justify-content: start;
  align-items: center;
}

.invite-reserve {
  display: none;
}

.dropdown-menu.profile-menu a.dropdown-item:hover svg path {
  fill: #2a7be4;
}

.dropdown-item {
  font-size: 14px;
  font-family: "Poppins";
}

.custom-badge {
  width: 74px;
  height: 19px;
  border-radius: 20px;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  margin: 0px auto;
}

.add-deal {
  width: 137px;
  height: 40px;
  background: #2f80ed;
}

.sponser-index-table th {
  background: #fcfcfc;
}

.sponser-index-card .card-body {
  padding: 12px 21px 0px 21px !important;
}

.deal-name {
  margin-top: -12px;
}

.form-control::placeholder {
  color: #a2acbd;
  /* opacity: 0.4; */
  font-size: 12px;
}

.sponser-profit-amount {
  justify-content: start;
}

.bg-custom-ligt {
  background: #fbfaff;
}

.scroller {
  height: 564px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.add-investor {
  width: 132px;
  height: 30px;
  color: white;
  padding: 0px;
}

.sponser-data-body .table-responsive {
  overflow-x: hidden;
}

.investor-data-body .table-responsive {
  overflow-x: hidden;
}

.out-margin {
  margin-left: -2px;
}

.shares-info {
  display: none;
}

.shares-detail {
  display: block;
}

.copy-item-shadow {
  /* background-image: linear-gradient(to right, black, white); */
  border-radius: 5px;
  position: relative;
}

.copy-item-shadow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background-image: linear-gradient(to right, black, black, white);
  z-index: 1;
  opacity: 0.7;
  border-radius: 5px;
}

.copy-item-shadow::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 100%;
  background-image: linear-gradient(to left, black, black, white);
  z-index: 1;
  opacity: 0.7;
  border-radius: 5px;
}

.deals li button {
  padding: 8px 16px 8px 0px;
  margin-right: 25px;
}

.ending {
  float: right;
}

.xp-topbar {
  height: 75px;
  padding: 10px 30px;
}

.xp-logobar {
  height: 75px;
}

.xp-contentbar {
  padding: 28px 40px;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fcfcfc;
}

.btn-check:focus + .btn {
  background: #012658;
  padding: 0px 8px 2px 8px;
  color: white;
  height: 17px;
  border-radius: 10px;
  box-shadow: none;
}

.filter-bar .btn {
  padding: 0px;
}

.open-deals td,
.closed-deals td {
  padding: 0rem 0.5rem !important;
}

#operatingCashFlow .opeClass:last-chil #operatingCashFlow + #removeCashFlow {
  margin-top: -33px;
}

@media (max-width: 375px) {
  .chat-footer {
    width: 360px;
  }
}

@media (max-width: 320px) {
  .chat-footer {
    width: 300px;
  }
}

/* 
==================
    Responsive
==================
*/
@media only screen and (max-width: 1024px) {
  .login-col-1,
  .forgot-col-1,
  .forgot-response-col-1,
  .reset-col-1 {
    padding: 54px 24px;
  }
  .login-col-2,
  .forgot-col-2,
  .forgot-response-col-2,
  .reset-col-2 {
    padding: 32px 24px;
  }
  .sponser-data-body .table-responsive {
    overflow-x: auto;
  }
  .investor-data-body .table-responsive {
    overflow-x: auto;
  }
}

@media (max-width: 780px) {
  body {
    overflow-x: hidden;
  }

  .xp-leftbar {
    position: fixed;
    left: -250px;
  }

  .xp-toggle-menu .xp-leftbar {
    position: fixed;
    left: 0px;
  }

  .xp-rightbar {
    margin-left: 0 !important;
  }

  .xp-menubar {
    text-align: right;
    right: -28px !important;
  }

  .xp-searchbar {
    margin-top: 20px;
  }

  .xp-footerbar {
    left: 0;
  }

  .xp-email-rightbar .email-open-box .open-email-head ul {
    text-align: left;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .login-col-2,
  .forgot-col-2,
  .forgot-response-col-2,
  .reset-col-2 {
    margin: 0px 0px;
  }
  .forgot-col-2 #bottom_text {
    margin-bottom: 0rem;
  }
  .forgot-response-col-2 #bottom_text {
    margin-bottom: 2rem;
  }
  h2.total-shares,
  .deals_shares,
  .deals-subdata {
    font-size: 14px;
  }
  .deals-data {
    font-size: 16px;
  }

  .deal-name {
    font-size: 21px;
  }

  .om-btn {
    margin-top: -8px;
    margin-bottom: 10px;
  }
  .sub-card {
    margin-bottom: 20px;
  }
  .xp-contentbar {
    padding: 21px 14px;
  }

  /* .custom-badge {
    margin-left: 0px;
  } */
}
@media only screen and (max-width: 576px) {
  .login-col-1,
  .forgot-col-1,
  .forgot-response-col-1,
  .reset-col-1 {
    padding: 26px 24px;
  }
  .login-col-2,
  .forgot-col-2,
  .forgot-response-col-2,
  .reset-col-2 {
    padding: 32px 24px;
  }
  .height-vh-100 {
    min-height: unset !important;
  }
  .chat-section {
    margin-top: 10px;
  }
  .company-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
  .compnay-mail {
    font-size: 14px;
  }
  .update-logo {
    font-size: 12px;
  }
}

/* @media only screen and (max-width: 576px) {
  
} */

/* Media queries for pages */
@media only screen and (max-width: 1024px) {
  .sponser-profile-table .del-btn {
    margin-left: 0px;
    margin-top: 10px;
  }

  .sponser-profile-table .del-btn img {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .copy-link,
  .save-btn {
    width: 110px;
  }
  .invite2-btn {
    width: 135px;
  }
  .fs-16 {
    font-size: 14px;
  }
  .fs-14 {
    font-size: 12px;
  }
  .fs-18 {
    font-size: 16px;
  }
  .sponser-percentage {
    justify-content: start;
  }
  .ce-cash-flow {
    margin-top: 15px;
  }
  .invite-all {
    display: none;
  }
  .invite-reserve {
    display: block;
  }
  .profile-img .user-img {
    height: 110px;
    width: 110px;
    margin-top: -45px;
  }
  .user-title {
    font-size: 20px;
    margin-left: 12px;
  }
  .user-email {
    font-size: 16px;
    margin-top: 100px;
    margin-left: 12px;
    border: 1px solid red;
  }
  .invester-index-table td {
    font-size: 14px;
  }
  .dark-box,
  .primary-box,
  .danger-box,
  .success-box {
    height: 14px;
  }
  .sponser-profit-amount {
    justify-content: start;
  }
  .fs-20 {
    font-size: 16px;
  }
  .om-btn {
    margin-top: -8px;
    margin-bottom: 10px;
  }
  .shares-info {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
  .shares-detail {
    display: none;
  }
  .ending {
    float: none;
  }
  .xp-contentbar {
    padding: 10px;
  }
  .sub-card {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 375px) {
  .invite2-btn {
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .user-title {
    margin-left: 3px;
    font-size: 15px;
  }
  .fs-24 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 320px) {
  .user-title {
    margin-left: 10px;
    font-size: 14px;
  }
  .xp-topbar .xp-notification .dropdown-menu {
    width: 330px;
  }
  .fs-24 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 992px) {
  .card-margin {
    margin-bottom: 0.8rem;
  }
}

.profile_update {
  margin-top: 2.5rem;
  position: absolute;
  top: 40px;
  left: 150px;
  cursor: pointer;
  color: blue;
}
@media (max-width: 426px) {
  .profile_update {
    position: absolute;
    top: 20px;
    left: 90px;
    cursor: pointer;
    color: blue;
  }
  .login-logo {
    margin-top: -4rem;
  }
  .add_newdeal {
    margin-bottom: 1rem;
  }
}

.button_color {
  background-color: #2b4479;
  color: #ffffff;
}
.button_color:hover {
  color: #ffffff;
}
.profile_background {
  background: linear-gradient(180deg, #8bb0ff -6.2%, #2b4479 115.17%);
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.15),
    2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.investor_desktop_view {
  display: block;
  padding: 50px 50px 10px 50px;

}

.investor_mobile_view {
  display: none;
}

@media(max-width: 768px){
  .investor_desktop_view {
    display: none;
  }
  
  .investor_mobile_view {
    display: block;
  }

}

@media only screen and (max-width: 1276px) {
  label.form-label {
    font-size: 11px;
  }
}
@media (max-width: 992) {
  label.form-label {
    font-size: 14px;
  }
}
